import { mapActions, mapState } from 'vuex';
import { $EventBus } from '@/main';
import moment from 'moment';

export default {
    name: "ListReport",

    data: () => ({
        listError: [],
        isLoading: false,
        loading: false,
        selected: [],
        selectedItems: [],
        filter: {
            company_id: 1,
            user_id: null,
            app_id: null,
            page: 1,
            per_page: 20,
            pagination: true,
        },
        companySelected: null,
        // search: "",
        headers: [
            {
                text: "Seleccionar",
                value: "actions",
                sortable: false,
                class: "primary--text",
            },
            {
                text: "Usuario",
                value: "username",
                align: "start",
                sortable: false,
                class: "primary--text",
            },
            {
                text:"Id de usuario",
                value: "user_id",
                align: "start",
                sortable: false,
                class: "primary--text"
            },
            {
                text: "Nombre de usuario",
                value: "name",
                sortable: false,
                class: "primary--text",
            },
            {
                text: "Estado de usuario",
                value: "user_active",
                sortable: false,
                class: "primary--text",
            },
            {
                text: "Aplicación",
                value: "application",
                align: "center",
                sortable: false,
                class: "primary--text",
            },
            {
                text: "Rol o perfil",
                value: "profile",
                sortable: false,
                class: "primary--text",
            },
            {
                text: "Gerencia",
                value: "management",
                sortable: false,
                class: "primary--text",
            },
            {
                text: "Servicio",
                value: "costCenter",
                sortable: false,
                class: "primary--text",
            },
            {
                text: "Estado de servicio",
                value: "costCenter_active",
                sortable: false,
                class: "primary--text",
            },
            {
                text: "Usuario asignación",
                value: "assigned_by",
                sortable: false,
                class: "primary--text",
            },
            {
                text: "Fecha asignación",
                value: "assignment_date",
                sortable: false,
                class: "primary--text",
            },

        ],
        currentPage: 1,
        editedItem: {},
        deletedItem: {},
        localListUserAccess: [],
        selectedItemCheck: [],
    }),

    computed: {
        users() {
            return this.$store.getters["users/getUsers"];
        },
        applications() {
            return this.$store.getters["applications/getApplications"];
        },

        ...mapState('permissions', ['listUserAccess', 'listUserAccessPagination'])

    },

    watch: {},

    created() {

        this.listenUsers();
        this.listenApplications();
        this.searchUser();
    },

    methods: {
        ...mapActions('permissions', ['getListUserAccess', 'deletePermissions', 'cleanUserPermissionList']),

        listenUsers() {
            this.$store.dispatch("users/getUsers");
        },

        listenApplications() {
            this.$store.dispatch("applications/getApplications");
        },

        showSnack(color = '', text = '') {
            $EventBus.$emit('showSnack', { color, text });
        },

        async searchUser() {
            this.loading = true;

            const { error } = await this.getListUserAccess(this.filter);

            if (error) {
                this.showSnack('warning', error);
                this.loading = false;
                return;
            }

            this.localListUserAccess = this.listUserAccess.map(item => {
                const isSelected = this.selectedItemCheck.some(selected => selected.costCenter_id === item.costCenter_id);
                return {
                    ...item,
                    check: isSelected
                };
            });

            this.loading = false;
        },

        customFilter(item, queryText, itemText) {
			const searchTerms = queryText.toLowerCase().split(' ');
			const fullName = itemText.toLowerCase();

			return searchTerms.every((term) => fullName.includes(term));
		},

        async searchApplication() {
            this.selectedItemCheck = [];
            this.filter.page = 1;
            await this.searchUser();
        },

        formatDate(dateString) {
            if (!dateString) {
                return '-';
            }
            return moment(dateString).format('DD/MM/YYYY');
        },

        toggleSelection(item) {

            // item.check = !item.check;

            // const index = this.selectedItemCheck.findIndex(i => i.costCenter_id === item.costCenter_id);
            // if (index === -1) {
            //     this.selectedItemCheck.push(item);
            // } else {
            //     this.selectedItemCheck.splice(index, 1);
            // }

            // console.log('check item', this.selectedItemCheck)

            const index = this.selectedItemCheck.findIndex(i => i.costCenter_id === item.costCenter_id);
            if (item.check) {
                if (index === -1) {
                    this.selectedItemCheck.push(item);
                }
            } else {
                if (index !== -1) {
                    this.selectedItemCheck.splice(index, 1);
                }
            }

        },

        async deletePermission() {
            this.$swal({
                text: '¿Estás seguro de que deseas realizar esta acción?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#0039a6',
                cancelButtonColor: 'grey',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Sí',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        const accesIds = this.selectedItemCheck.map(res => res.costCenter_id)

                        const { error, data } = await this.deletePermissions({
                            company_id: 1,
                            accesIds: accesIds
                        })

                        if (error) this.showSnack('warning', error);

                        data.result === 'warning' ? this.showSnack(data.result, data.message) : this.showSnack('green', 'Los permisos fueron eliminados');

                        this.selectedItemCheck = [];
                        this.filter.page = 1;
                        await this.searchUser(this.filter);
                    } catch (error) {
                        console.log(error)
                    }
                }
            });
        },
    },
    beforeDestroy() {
        this.cleanUserPermissionList();
    },
    components: {
        // CreateProfileDialog,
        // EditProfileDialog,
        // DeleteProfileDialog,
        // SearchInput,
    },
};
