import { $EventBus } from "@/main";
export default {
  name: "Assignments",

  data: () => ({
    headers: [
      {
        text: "Actividad",
        align: "start",
        sortable: false,
        value: "name",
      },
      {
        text: "Acción",
        align: "center",
        sortable: false,
        value: "actions",
      },
    ],
    search: "",
    searchAssigned: "",
    applicationSelected: null,
    profileSelected: null,
    assignedActivities: [],
    timeout: null,
    isLoadingSave: false,
  }),

  computed: {
    widthPage() {
      return this.$vuetify.breakpoint.width;
    },

    profiles() {
      return this.$store.getters["profiles/getProfilesList"];
    },

    applications() {
      return this.$store.getters["applications/getApplicationsList"];
    },

    activities() {
      return this.listUnassignedActivities(
        this.$store.getters["activities/getActivitiesForAssignment"]
      );
    },

    isLoading() {
      return this.$store.state.activities.isLoading;
    },

    isLoadingAssigned() {
      return this.$store.state.activities.isLoadingAssigned;
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {      
      this.$store.dispatch("activities/cleanActivities");
      this.$store.dispatch("applications/getApplicationsList");
    },

    listUnassignedActivities(activities = []) {
      let unassignedActivities = activities;
      const assignedActivities = this.assignedActivities;
      if (Array.isArray(assignedActivities) && assignedActivities.length > 0) {
        unassignedActivities = activities.filter((activity) => {
          return !assignedActivities.some((item) => {
            return item.id == activity.id;
          });
        });
      }
      return unassignedActivities;
    },

    assignActivity(activity) {
      if (this.profileSelected) {
        this.assignedActivities.push({ ...activity, isNew: true });
      } else {
        $EventBus.$emit("showSnack", {
          color: "warning",
          text: "Seleccionar Perfil",
        });
      }
    },

    denyActivity(activity) {
      if (activity) {
        const index = this.assignedActivities.findIndex((item) => {
          return item.id == activity.id;
        });
        this.assignedActivities.splice(index, 1);
      }
    },

    showSnack(color = "", text = "") {
      $EventBus.$emit("showSnack", { color, text });
    },

    async saveAssignment() {
      if (this.profileSelected) {
        try {
          this.isLoadingSave = true;
          let data = {
            profile_id: this.profileSelected,
            activities: this.assignedActivities,
          };
          await this.$store.dispatch("assignments/saveAssignment", data);
          await this.$store.dispatch("activities/getActivitiesByRole", {
            role_id: this.profileSelected,
          });
          this.assignedActivities =
            this.$store.state.activities.assignedActivities;
          this.showSnack("success", "Asignación guardada");
        } catch (error) {
          this.showSnack("red", "No se pudo realizar está operación");
        } finally {
          this.isLoadingSave = false;
        }
      } else {
        this.showSnack("warning", "Seleccionar Perfil");
      }
    },

    listenSearch(value) {
      clearTimeout(this.timeout);
      if (value.length > 1 || value == "") {
        this.timeout = setTimeout(() => {
          this.$store.dispatch("activities/getActivitiesByApplication", {
            id: this.applicationSelected,
            search: value,
          });
        }, 800);
      }
    },

    listenApplication() {
      this.profileSelected = null;
      this.assignedActivities = [];
      this.$store.dispatch("activities/getActivitiesByApplication", {
        id: this.applicationSelected,
      });
      this.$store.dispatch("profiles/getProfilesByApplication", {
        application_id: this.applicationSelected,
      });
    },

    listenClickApplication() {
      const hasUnsavedData = this.assignedActivities.some((item) => {
        return item?.isNew;
      });
      if (hasUnsavedData) {
        this.showSnack("warning", "Tienes asignaciones sin guardar");
      }
    },

    async listenProfile(id) {
      await this.$store.dispatch("activities/getActivitiesByRole", {
        role_id: id,
      });
      this.assignedActivities = await this.$store.getters[
        "activities/getAssignedActivities"
      ];
    },

    cancelAssignment() {
      this.$nextTick(() => {
        this.profileSelected = null;
        this.assignedActivities = [];
      });
    },
  },
  destroyed() {
    this.$store.dispatch("activities/cleanActivities");
  },
  components: {},
};
